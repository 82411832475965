import { Component, Prop, Vue } from 'vue-property-decorator'
import '@toast-ui/editor/dist/toastui-editor.css'
// import { Editor } from '@toast-ui/vue-editor'
//import Editor from '@tinymce/tinymce-vue'
import { ArticleEndPoint } from '@/Models/ArticleEndPoint'
import ExpandableButtonSuccess from '@/components/ExpandableButtonSuccess/ExpandableButtonSuccess.vue'
import ModalComponent from '@/components/ModalComponent/ModalComponent.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import VueTrumbowyg from 'vue-trumbowyg'
import 'trumbowyg/dist/ui/trumbowyg.css'
import 'trumbowyg/dist/trumbowyg.min.js'

import 'trumbowyg/dist/plugins/colors/trumbowyg.colors.js'
import 'trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.min.css'
import 'trumbowyg/dist/plugins/pasteembed/trumbowyg.pasteembed.js'
import 'trumbowyg/dist/plugins/fontsize/trumbowyg.fontsize.min.js'
import 'trumbowyg/dist/plugins/fontsize/trumbowyg.fontsize.js'
import 'trumbowyg/dist/plugins/fontfamily/trumbowyg.fontfamily.min.js'
import 'trumbowyg/dist/plugins/fontfamily/trumbowyg.fontfamily.js'
import 'trumbowyg/dist/plugins/table/ui/trumbowyg.table.min.css'
import 'trumbowyg/dist/plugins/table/ui/trumbowyg.table.css'
import 'trumbowyg/dist/plugins/table/trumbowyg.table.min.js'
import 'trumbowyg/dist/plugins/table/trumbowyg.table.js'
import 'trumbowyg/dist/plugins/pasteimage/trumbowyg.pasteimage.min.js'
import 'trumbowyg/dist/plugins/resizimg/trumbowyg.resizimg.min.js'
import 'jquery-resizable-dom'
import $ from 'jquery'

//declare const tinymce: any
@Component({
  components: {
    ModalComponent,
    ValidationObserver,
    ValidationProvider,
    ExpandableButtonSuccess,
    VueTrumbowyg,
  },
})
export default class index extends Vue {
  @Prop({ required: false })
  articleReplace!: ArticleEndPoint
  @Prop({ required: false })
  action!: string
  @Prop({ required: false })
  html!: any
  showModal = true
  emit = 'create-article'
  isLoading = false
  htmlLocal = ''
  content = ''
  config = {
    btnsAdd: ['foreColor', 'backColor', 'fontsize', 'fontfamily'],
    // Limit toolbar buttons
    btns: [
      /*  ["viewHTML"], */
      ['undo', 'redo'], // Only supported in Blink browsers
      ['formatting'],
      ['strong', 'em' /* "del" */],
      ['superscript', 'subscript'],
      ['link'],
      /* ['insertImage'], */
      ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
      /*  ["unorderedList", "orderedList"], */
      /* ["horizontalRule"], */
      ['removeformat'],
      /* ["fullscreen"], */
      ['foreColor'],
      ['backColor'],
      ['fontsize'],
      ['fontfamily'],
      ['table', 'tableCellBackgroundColor', 'tableBorderColor'],
    ],
  }

  // demoBaseConfig: any = {
  //   selector: 'textarea#classic',
  //   plugins:
  //     'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons',
  //   editimage_cors_hosts: ['picsum.photos'],
  //   menubar: 'file edit view insert format tools table help',
  //   toolbar:
  //     'undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
  //   toolbar_sticky: true,
  //   toolbar_sticky_offset: 108,
  //   autosave_ask_before_unload: true,
  //   autosave_interval: '30s',
  //   autosave_prefix: '{path}{query}-{id}-',
  //   autosave_restore_when_empty: false,
  //   autosave_retention: '2m',
  //   image_advtab: true,
  //   importcss_append: true,
  //   file_picker_callback: (cb: any, value: any, meta: any) => {
  //     const input = document.createElement('input')
  //     input.setAttribute('type', 'file')
  //     input.setAttribute('accept', 'image/*')

  //     input.addEventListener('change', (e) => {
  //       const file = (e.target as any).files[0]

  //       const reader = new FileReader()
  //       reader.addEventListener('load', () => {
  //         /*
  //           Note: Now we need to register the blob in TinyMCEs image blob
  //           registry. In the next release this part hopefully won't be
  //           necessary, as we are looking to handle it internally.
  //         */
  //         const id = 'blobid' + new Date().getTime()
  //         const blobCache = tinymce.activeEditor.editorUpload.blobCache
  //         const base64 = (reader.result as any)?.split(',')[1]
  //         const blobInfo = blobCache.create(id, file, base64)
  //         blobCache.add(blobInfo)

  //         /* call the callback and populate the Title field with the file name */
  //         cb(blobInfo.blobUri(), { title: file.name })
  //       })
  //       reader.readAsDataURL(file)
  //     })

  //     input.click()
  //   },
  //   templates: [
  //     {
  //       title: 'New Table',
  //       description: 'creates a new table',
  //       content:
  //         '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>',
  //     },
  //     {
  //       title: 'Starting my story',
  //       description: 'A cure for writers block',
  //       content: 'Once upon a time...',
  //     },
  //     {
  //       title: 'New list with dates',
  //       description: 'New List with dates',
  //       content:
  //         '<div class="mceTmpl"><span class="cdate">cdate</span><br><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
  //     },
  //   ],
  //   template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
  //   template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
  //   height: 600,
  //   image_caption: true,
  //   quickbars_selection_toolbar:
  //     'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
  //   noneditable_class: 'mceNonEditable',
  //   toolbar_mode: 'sliding',
  //   contextmenu: 'link image table',
  //   skin: 'oxide',
  //   content_css: 'default',
  //   content_style:
  //     'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }',
  // }

  mounted() {
    console.log(this.html)
    this.htmlLocal = this.html
    this.config
  }

  save_page() {
    this.$emit('save_pages', {
      pages: [
        {
          html: this.htmlLocal,
        },
      ],
    })
  }
  onTrumbowygBlur() {
    console.log('blur')
  }

  // ColorEditor(){
  //   $('#editor').trumbowyg({
  //     btns: [
  //       ['foreColor', 'backColor']
  //     ]
  //   })
  // }
  //   $('#my-editor').trumbowyg({
  //     btns: [
  //         ['foreColor', 'backColor']
  //     ]
  // });
  // $(function() {
  //   $('#editor').trumbowyg({
  //     btns: [
  //       ['foreColor', 'backColor']
  //     ]
  //   });
  // });
}
