import { Component, Vue, Prop } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { ArticleEndPoint } from '@/Models'
import { httpMulti } from '@/services/http'

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class ArticleModal extends Vue {
  @Prop({ required: true }) action!: string
  @Prop({ required: true }) article!: ArticleEndPoint
  image: File | null = null
  base_image = 'https://bulma.io/images/placeholders/256x256.png'
  article_base: ArticleEndPoint = {
    title: '',
    id: null,
    description: '',
    image: 'https://bulma.io/images/placeholders/256x256.png',
    url: null,
    pages: [],
    creator: null,
    created_at: '',
  }

  title = this.action == 'create' ? 'Ingrese un articulo' : 'Editar el articulo'
  iconLeft = this.action == 'create' ? 'plus' : 'pencil'
  name = ''

  mounted() {
    if (this.action == 'create') {
      this.article_base = {
        title: '',
        id: null,
        description: '',
        image: 'https://bulma.io/images/placeholders/256x256.png',
        url: null,
        pages: [],
        creator: null,
        created_at: '',
      }
    } else {
      this.article_base = { ...this.article }
    }
  }

  async upload_file(file: File) {
    try {
      const form = new FormData()
      form.append('file', file)
      const res = await httpMulti.post('cms/file_upload/', form)
      this.article_base.image = res.data
    } catch (error) {
      this.article.image = null
      console.error((error as any).messages)
    }
  }

  confirmation() {
    this.$emit('create-edit-article', this.article_base)
    this.$emit('close')
  }
}
