import { Component, Vue } from 'vue-property-decorator'
import http from '@/services/http'

//import http from '@/services'

@Component
export default class TemplatatesForm extends Vue {
  labelPosition = 'left'
  position = null
  size = 'medium'

  title = ''

  navName1 = ''
  navLink1 = ''
  navName2 = ''
  navLink2 = ''
  navName3 = ''
  navLink3 = ''
  navName4 = ''
  navLink4 = ''

  listnavLinks: Array<any> = []

  footerNavName1 = ''
  footerNavLink1 = ''
  footerNavName2 = ''
  footerNavLink2 = ''

  listfooterLinks: Array<any> = []

  templates: any = ['default']
  templateSelected = ''

  jsonTemplateDefault = {
    id: 1,
    archivo: {
      template: {
        title: 'template 1',
        pages: [],
      },
    },
  }
  page = {
    id: 1,
    title: this.title,
    header: {
      navLink: [],
    },
    body: {
      section: [],
    },
    footer: {
      FooterLink: [],
    },
  }

  section = {
    id: 1,
    type: '',
    imgSrc: '',
    title: '',
    content: '',
  }

  async createtemplate() {
    this.listnavLinks = [
      {
        id: 1,
        text: this.navName1,
        link: this.navLink1,
      },
      {
        id: 1,
        text: this.navName2,
        link: this.navLink2,
      },
      {
        id: 1,
        text: this.navName3,
        link: this.navLink3,
      },
      {
        id: 1,
        text: this.navName4,
        link: this.navLink4,
      },
    ]
    this.listfooterLinks = [
      {
        id: 1,
        text: this.footerNavName1,
        link: this.footerNavLink1,
      },
      {
        id: 1,
        text: this.footerNavName2,
        link: this.footerNavLink2,
      },
    ]
    this.listnavLinks.forEach((nav) => {
      this.page.header.navLink.push(nav as never)
    })
    this.page.body.section.push(this.section as never)
    this.listfooterLinks.forEach((footer) => {
      this.page.footer.FooterLink.push(footer as never)
    })
    this.jsonTemplateDefault.archivo.template.pages.push(this.page as never)
    try {
      await http.post('/hoshin/page/', this.jsonTemplateDefault)
      this.$buefy.toast.open({
        message: 'Usuario agregado',
        type: 'is-success',
      })
    } catch (error) {
      this.$buefy.toast.open({
        message: 'Ha ocurrido un error, intente nuevamente',
        type: 'is-danger',
      })
    }
  }
}
